import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled, { createGlobalStyle } from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const GlobalStyle = createGlobalStyle`
  body {
    padding-top: 0 !important;
    min-height: 0 !important;
  }
`;
const ScrollableContainer = styled.div`
  @media (max-width: 768px) {
    overflow-y: auto;
    max-height: 100vh;
  }
`;

class Tos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIndex: 0,
      imagesLoaded: false,
    };
    this.images = [
      "/images/comingsoon/1.jpg",
      "/images/comingsoon/2.jpg",
      "/images/comingsoon/3.jpg",
      "/images/comingsoon/4.jpg",
      "/images/comingsoon/5.jpg",
    ];

    this.preloadedImages = [];
  }

  componentDidMount() {
    this.preloadImages(this.images, () => {
      this.setState({ imagesLoaded: true });
      this.interval = setInterval(this.changeImage, 10000);
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  preloadImages = (imageUrls, callback) => {
    let loadedCount = 0;
    imageUrls.forEach((url, index) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${url}`;
      img.onload = () => {
        loadedCount++;
        this.preloadedImages[index] = img;
        if (loadedCount === imageUrls.length) {
          callback();
        }
      };
    });
  };

  changeImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex + 1) % this.images.length,
    }));
  };

  render() {
    const { currentImageIndex } = this.state;
    const backgroundImageUrl = `${process.env.PUBLIC_URL}${this.images[currentImageIndex]}`;
    return (
      <div className=" container-fluid ">
        <center>
          <div
            className="homepage-bg-color fade-background"
            style={{
              //paddingTop: 120,
              color: "#ffffff",
              fontWeight: "600",
              textShadow: "1px 1px #000000",
              marginRight: -15,
              marginLeft: -15,

              //backgroundImage: `  linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${
              //  process.env.PUBLIC_URL + "/images/bg/10.jpg"
              // })`,

              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${backgroundImageUrl})`,
              //background: "linear-gradient(to top, #000000, #434343)",
            }}
          >
            <GlobalStyle />

            <div className="container-comingsoon">
              <div className="comingsoon-pages-menu">
                <Link
                  to="/comingsoon"
                  className="comingsoon-pages-menu-backlink"
                >
                  <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                </Link>
                <h1 className="comingsoon-pages-menu-header">
                  Terms of Service
                </h1>
              </div>
              <ScrollableContainer
                className="p-comingsoon"
                style={{
                  //maxWidth: "650px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginRight: "20px",
                  marginTop: "42px",
                  marginLeft: "20px",
                  paddingBottom: "180px",
                  paddingTop: "20px",
                  opacity: 0.8,
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                <p className="comingsoon-text">
                  <b>Introduction</b>
                  <p className="comingsoon-text">
                    These terms of service govern your use of the Bealyo app and
                    website. By using our services, you agree to these terms.
                  </p>
                  <b>Email Collection:</b>
                  <p className="comingsoon-text">
                    By providing your email address on our coming soon page, you
                    consent to us collecting and storing your email address for
                    the purpose of sending you notifications about the launch of
                    our app, Bealyo. We will only use your email address for
                    this specific purpose and will not share it with third
                    parties without your consent. You may unsubscribe from these
                    notifications at any time by following the unsubscribe
                    instructions provided in the emails.
                  </p>
                  <b>App Usage</b>
                  <p className="comingsoon-text">
                    You may use our app for personal, non-commercial purposes.
                    You may not use our app in a way that violates applicable
                    laws or regulations.
                  </p>
                  <b>Intellectual Property</b>
                  <p className="comingsoon-text">
                    Bealyo owns all intellectual property rights to our app and
                    its content.
                  </p>
                  <b>Disclaimer of Warranties</b>
                  <p className="comingsoon-text">
                    We do not guarantee the performance or accuracy of our app.
                  </p>
                  <b>Limitation of Liability</b>
                  <p className="comingsoon-text">
                    We are not liable for any damages or losses arising from
                    your use of our app.
                  </p>

                  <b>Indemnification</b>
                  <p className="comingsoon-text">
                    You agree to indemnify us for any losses or damages arising
                    from your violation of these terms.
                  </p>

                  <b>Termination</b>
                  <p className="comingsoon-text">
                    We may terminate your account at any time for any reason.
                  </p>

                  <b>Changes to these Terms of Service</b>
                  <p className="comingsoon-text">
                    We may update these terms of service from time to time. We
                    will notify you of any significant changes.
                  </p>

                  <b>Contact Information</b>
                  <p className="comingsoon-text">
                    If you have any questions about these terms of service,
                    please contact us at{" "}
                    <a className="menu-link" href="mailto:admin@bealyo.com">
                      admin@bealyo.com
                    </a>
                  </p>
                </p>
              </ScrollableContainer>
            </div>
          </div>
        </center>
      </div>
    );
  }
}

export default Tos;
