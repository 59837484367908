import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled, { createGlobalStyle } from "styled-components";
import "../menu/menu.css"; // Assuming you have a CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Link, withRouter } from "react-router-dom";
const GlobalStyle = createGlobalStyle`
  body {
    padding-top: 0 !important;
    min-height: 0 !important;
  }
`;

const ScrollableContainer = styled.div`
  @media (max-width: 768px) {
    overflow-y: auto;
    max-height: 100vh;
  }
`;

class Privacy extends Component {
  constructor(props) {
    super(props);

    //this.state = {
    // isTop: true,
    // };
    //this.onScroll = this.onScroll.bind(this);

    this.state = {
      currentImageIndex: 0,
      imagesLoaded: false,
    };
    this.images = [
      "/images/comingsoon/1.jpg",
      "/images/comingsoon/2.jpg",
      "/images/comingsoon/3.jpg",
      "/images/comingsoon/4.jpg",
      "/images/comingsoon/5.jpg",
    ];

    this.preloadedImages = [];
  }

  componentDidMount() {
    this.preloadImages(this.images, () => {
      this.setState({ imagesLoaded: true });
      this.interval = setInterval(this.changeImage, 10000);
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  preloadImages = (imageUrls, callback) => {
    let loadedCount = 0;
    imageUrls.forEach((url, index) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${url}`;
      img.onload = () => {
        loadedCount++;
        this.preloadedImages[index] = img;
        if (loadedCount === imageUrls.length) {
          callback();
        }
      };
    });
  };

  changeImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex + 1) % this.images.length,
    }));
  };

  render() {
    const { currentImageIndex } = this.state;
    const backgroundImageUrl = `${process.env.PUBLIC_URL}${this.images[currentImageIndex]}`;

    return (
      <div className=" container-fluid ">
        <center>
          <div
            className="homepage-bg-color fade-background"
            style={{
              //paddingTop: 120,
              color: "#ffffff",
              fontWeight: "600",
              textShadow: "1px 1px #000000",
              marginRight: -15,
              marginLeft: -15,

              //backgroundImage: `  linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${
              //  process.env.PUBLIC_URL + "/images/bg/10.jpg"
              // })`,
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${backgroundImageUrl})`,

              //background: "linear-gradient(to top, #000000, #434343)",
            }}
          >
            <GlobalStyle />
            <div className="comingsoon-pages-menu">
              <Link to="/comingsoon" className="comingsoon-pages-menu-backlink">
                <FontAwesomeIcon size="lg" icon={faArrowLeft} />
              </Link>
              <h1 className="comingsoon-pages-menu-header">Privacy Policy</h1>
            </div>

            <ScrollableContainer
              className="p-comingsoon"
              style={{
                //maxWidth: "650px",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginRight: "20px",
                marginTop: "42px",
                marginLeft: "20px",
                paddingBottom: "180px",
                paddingTop: "20px",
                opacity: 0.8,
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              <b>Introduction</b>
              <p className="comingsoon-text">
                Bealyo is committed to protecting your privacy. This privacy
                policy explains how we collect, use, and protect your personal
                information when you use our app and website.
              </p>
              <b>Information Collection</b>
              <p className="comingsoon-text">
                We may collect the following types of information: Personal
                information (name, email, etc.) Usage data (app interactions,
                device information) Goal and challenge data We collect this
                information when you create an account, use our app, or
                communicate with us.
              </p>
              <b>Information Usage</b>
              <p className="comingsoon-text">
                We use your information to: Provide our app's services
                Personalize your user experience Improve our app Communicate
                with you (updates, notifications) We will not share your
                personal information with third parties without your consent,
                except as required by law.
              </p>

              <b> Data Security</b>
              <p className="comingsoon-text">
                We take reasonable measures to protect your data from
                unauthorized access, disclosure, or use.
              </p>

              <b>User Rights</b>
              <p className="comingsoon-text">
                You have the right to: Access your personal information Correct
                inaccuracies in your information Delete your personal
                information Object to the processing of your personal
                information Third-Party Services We may use third-party services
                (e.g., analytics, cloud storage). These services may collect and
                process your data.
              </p>
              <b>Changes to this Privacy Policy</b>
              <p className="comingsoon-text">
                We may update this privacy policy from time to time. We will
                notify you of any significant changes.  
              </p>

              <b>Contact Information</b>

              <p className="comingsoon-text">
                If you have any questions about this privacy policy, please
                contact us at{" "}
                <a className="menu-link" href="mailto:admin@bealyo.com">
                  admin@bealyo.com
                </a>
              </p>
            </ScrollableContainer>
          </div>
        </center>
      </div>
    );
  }
}

export default Privacy;
