import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
//import Navbar from "../layout/Navbar";
//import Breadcrumb from "react-bootstrap/Breadcrumb";
import classnames from "classnames";
import ComingSoonm from "../menu/comingsoonm.component";
//import "../ontheway/ontheway.css"; // Assuming you have a CSS file for styling
import styled, { createGlobalStyle } from "styled-components";
import ReactGA from "react-ga4";

// Initialize GA4
ReactGA.initialize("G-3VKRXQ973F");

const GlobalStyle = createGlobalStyle`
  body {
    padding-top: 0 !important;
        min-height: 0 !important;
  }
          .p-comingsoon {
    word-break: break-word;
    overflow-wrap: break-word;
  }
`;

const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 220px;
  height: 40px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#47484a" : "#33343A")};
  border: 1px solid ${(props) => (props.error ? "red" : "transparent")};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
`;

const StyledButton = styled.button`
  width: 100px;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${(props) => (props.disabled ? "#E0E0E0" : "#1D9BF0")};
  color: ${(props) => (props.disabled ? "#404040" : "#fff")};
  font-weight: ${(props) => (props.disabled ? "normal" : "500")};
  border: none;
  font-size: medium;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const ResponsiveSpan = styled.span`
  font-size: 7vw;
  font-weight: bold;
  text-shadow: 2px 2px 4px #000000, -2px -2px 4px #333333;

  @media (max-width: 1200px) {
    font-size: 7vw;
  }

  @media (max-width: 992px) {
    font-size: 7vw;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
  }

  @media (max-width: 576px) {
    font-size: 10vw;
  }
`;

/* where to use the GlobalStyle? */

class ONTHEWAY extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      tou: "",
      errors: {},
      submitted: false, // New state to track submission status
      validEmail: false,
      showMessage: true,
      loading: false, // New state to track loading status
    };
    this.timeoutId = null;
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  validateEmail = (email) => {
    // Basic regex for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ validEmail: this.validateEmail(e.target.value) });
    //this.state.validEmail = this.validateEmail(e.target.value);

    // Track input change event
    ReactGA.event({
      category: "User",
      action: "Input Change For Notify Email",
      label: e.target.id,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    //const { email } = this.state;

    const newSoonUser = {
      email: this.state.email,
    };

    // Validate email
    if (!newSoonUser.email || !this.validateEmail(newSoonUser.email)) {
      this.setState({ errors: { email: "Invalid email address" } });
      console.log("Email not Valid");
      return; // Stop further execution if email is invalid
    } else {
      this.setState({ loading: true });

      // Track form submission event
      ReactGA.event({
        category: "User",
        action: "Notify Clicked",
        label: "Notify Me Form",
      });

      axios
        .post(process.env.REACT_APP_API_URL + "users/soonuser/add", newSoonUser)
        .then((res) => {
          console.log(res.data);

          // Track form submission event
          ReactGA.event({
            category: "User",
            action: "Notify Submission",
            label: "Notify Me Form",
          });

          // Set submitted to true after successful response
          this.setState({
            submitted: true,
            errors: {},
            loading: false, // set loading to false after success
          });
        })
        .catch((err) => {
          //console.log(err.response.data);
          this.setState({ loading: false });
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.submitted && !prevState.submitted) {
      this.setState({ showMessage: true });
      this.timeoutId = setTimeout(() => {
        this.setState({ showMessage: false });
      }, 10000); // 10 seconds
    }
  }
  render() {
    const { errors, submitted, showMessage } = this.state;
    return (
      <center>
        <GlobalStyle />
        <div className="container-comingsoon">
          <div>
            <ResponsiveSpan>COMING SOON</ResponsiveSpan>
            <p
              className="p-comingsoon"
              style={{
                maxWidth: "650px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                paddingTop: "20px",
                marginLeft: "8px",
                marginRight: "8px",
              }}
            >
              Bealyo, powered by AI tools and technology, is almost here. Sign
              up now to be among the first to experience its personalized goal
              setting, habit tracking, and challenge features. Achieve your
              goals, overcome challenges, and become the best version of
              yourself.
            </p>
          </div>
          <div>
            {showMessage && (
              <>
                {submitted ? (
                  <div className="container-comingsoon">
                    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                      <p
                        className="p-comingsoon "
                        style={{ maxWidth: "600px", fontSize: "12px" }}
                      >
                        Thanks for signing up to be notified when our app is
                        ready! We appreciate your patience. In the meantime, why
                        not spread the word and invite your friends to join the
                        waitlist? Together, we can make our app launch even more
                        exciting!
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <center>
                      <form noValidate onSubmit={this.onSubmit}>
                        {/*}
                  {errors.email ? (
                    <div>
                      <span className="red-text">{errors.email}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                    */}

                        <div
                          className="inlineForm"
                          style={{ alignItems: "center" }}
                        >
                          <InputButtonContainer>
                            <StyledInput
                              onChange={this.onChange}
                              value={this.state.email}
                              error={errors.email}
                              disabled={this.state.loading ? true : false}
                              id="email"
                              type="email"
                              placeholder="Your email"
                              className={classnames("form-control", {
                                invalid: errors.email,
                              })}
                            />
                            <StyledButton
                              type="submit"
                              disabled={!this.state.validEmail}
                            >
                              {this.state.loading
                                ? "Submitting.."
                                : "Notify Me"}
                            </StyledButton>
                          </InputButtonContainer>
                        </div>

                        {/** 
                        <div
                          className="inlineForm"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            className="form-group"
                            style={{ marginRight: "3px", paddingTop: "5px" }}
                          >
                            <input
                              onChange={this.onChange}
                              value={this.state.email}
                              error={errors.email}
                              disabled={this.state.loading ? true : false}
                              id="email"
                              type="email"
                              style={{
                                width: "230px",
                                height: "40px",
                                color: "white",
                                backgroundColor: this.state.loading
                                  ? "#1c1c1b"
                                  : "#333331",
                                borderColor: errors.email ? "red" : "", // Set border color to red if errors.email is true
                              }}
                              placeholder="Your email"
                              className={classnames("form-control", {
                                invalid: errors.email,
                              })}
                            />
                          </div>

                          <div
                            className="form-group"
                            style={{ marginTop: "-10px" }}
                          >
                            <button
                              style={{
                                width: "100px",
                                height: "40px",
                                borderRadius: "3px",
                                //letterSpacing: "0px",
                                //marginTop: "1rem",
                                backgroundColor: this.state.validEmail
                                  ? "#1D9BF0"
                                  : "#1D9BF0",
                              }}
                              type="submit"
                              disabled={!this.state.validEmail}
                              className="btn btn-info"
                            >
                              {this.state.loading
                                ? "Submitting.."
                                : "Notify Me"}
                            </button>
                          </div>
                        </div>
                        */}
                      </form>
                      {this.state.loading && (
                        <div style={{ marginTop: "10px" }}>
                          {/*  <span>Loading...</span>*/}
                        </div>
                      )}
                    </center>
                  </div>
                )}
              </>
            )}

            <ComingSoonm />
          </div>
        </div>
      </center>
    );
  }
}

export default ONTHEWAY;
